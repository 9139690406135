












import { Vue, Component, Watch } from 'vue-property-decorator';
import { staticwebModule } from '@/store';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import moment from 'moment';
import firebase from 'firebase';

declare const FB: any;
declare const Kakao: any;
declare const window: any;

@Component
export default class Static extends Vue {
  public origin: any = `${window.location.origin}`;
  public swiper: any = null;

  public beforeRouteEnter(to, from, next) {
    staticwebModule.fetchStaticweb(to.params.staticwebId).then(() => {
      next((vm) => {
        if (
          document.referrer.includes('c1.poing.co.kr') &&
          to.fullPath.includes('adEvent')
        ) {
          staticwebModule.fetchMainPopup().then((res) => {
            if (vm.popupContent !== null && vm.popupContent.url && vm.$cookies.get('todayPopup') !== 'Y' && vm.$cookies.get('todayAppDownload') === 'Y') {
              vm.adEvent(vm.popupContent.id);
            }
          });
        }
        setTimeout(() => {
          vm.activeSwiper();
        }, 100);
        vm.staticwebResponsive();
        document.querySelectorAll('[data-end-date]').forEach((row) => {
          row.innerHTML = ''
        })
      });
    });
  }
  public mounted() {
    this.dDay();
    this.shareAction();
    this.staticwebResponsive();
    window.addEventListener('resize', () => {
       this.staticwebResponsive();
    });
    this.staticLink();

    if (this.staticweb.id === 1920) {
      this.addClickEvent();
      this.addClassName();
    }
  }

  public addClassName() {
    const el = document.getElementsByClassName('module_img_btn');
    el[0].classList.add('custom');
    console.log(el);
  }

  public addClickEvent() {
    const el = document.getElementsByClassName('module_img');
    const imgList = [
      'http://c1.poing.co.kr/original/images/static/61a6d534d0724__.png',
      'http://c1.poing.co.kr/original/images/static/61a6d53155a6d__.jpg',
      'http://c1.poing.co.kr/original/images/static/619ddbfce8b5f__.png',
      'http://c1.poing.co.kr/original/images/static/619ddbf83e840__.jpg',
      'http://c1.poing.co.kr/original/images/static/619ddcc877a45__.png'
    ];
    for (let i = 0; i < el.length; i++) {
      const img: any = el[i].children[0].children[0].children[0];

      if (img.src === imgList[0] || img.src === imgList[1]) {
        img.addEventListener('click', this.copyText);
      } else if (img.src === imgList[2] || img.src === imgList[3]) {
        img.addEventListener('click', () => {
          this.movePage('iamtaxi');
        });
      } else if (img.src === imgList[2]) {
        img.addEventListener('click', () => {
          this.movePage('poing');
        });
      }
    }
  }

  public copyText() {
    const t = document.createElement("textarea");
    document.body.appendChild(t);
    t.value = 'IMPOING';
    t.select();
    document.execCommand('copy');
    document.body.removeChild(t);

    this.$q.notify({
      message: '쿠폰 코드가 복사되었습니다.',
      html: true,
      classes: 'custom-notify'
    });
  }

  public movePage(type: string) {
    if (type === 'iamtaxi') {
      location.href = 'https://imforyou.page.link/FgzB';
    } else if (type === 'poing') {
      if (this.isApp) {
        if (this.version === 'android') {
          location.href = 'poing://main/ticket_list';
          window.poingApp.close();
        } else {
          const messageToPost = { method: 'close' };
          window.webkit.messageHandlers.scriptHandler.postMessage(messageToPost);
        }
      } else {
        this.$router.push('/');
      }
    }
  }

  public adEvent(ad_id: any) {
    const eventName = 'select_popup_ad';
    const eventParams: any = {
      id: null,
      static_id: null,
      static_title: null,
      link: null
    }
    eventParams.id = Number(ad_id);
    eventParams.static_id = Number(this.staticweb.id);
    eventParams.static_title = this.staticweb.title;
    eventParams.link = this.$route.path;
    console.log('전면광고 클릭이벤트 수집');
    firebase.analytics().logEvent(eventName, eventParams);
  }

  public staticLink() {
    if (this.isApp) {
      this.$el.querySelectorAll('a').forEach((button) => {
        const scheme = button.getAttribute('data-scheme');
        const path = button.pathname;
        const params = button.search;
        let href = '';
        if (!scheme) {
          href = `${this.origin}${path}${params}`;
        } else {
          href = scheme;
        }
        button.setAttribute('href', href);
      });
    }
    // else {
    //   document.querySelectorAll('a').forEach((button: any) => {
    //     const path = button.pathname;
    //     const params = button.search;
    //     button.setAttribute('href', `${this.origin}${path}${params}`);
    //   })
    // }
  }

  get isApp() {
    if (this.$route.query._from) return true;
    else return false;
  }

  get version() {
    const os: any = this.$route.query._version ? this.$route.query._version : '';
    if (os) return os.split('_')[0];
    else return '';
  }

  public activeSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    this.swiper = new Swiper('.staticweb-wrap .swiper-container', {
      loop: true,
      autoplay: {
        delay: 3000
      },
      observer: true,
      observeParents: true,
      pagination: {
        el: '.staticweb-wrap .swiper-pagination',
        clickable: true
      }
    });
  }

  public staticwebResponsive() {
    const size = window.innerWidth;
    if (size <= 768) {
      document.querySelectorAll('[data-mobile-imgsrc]' as any).forEach((row) => {
        const mobileImg: any = row.getAttribute('data-mobile-imgsrc');
        row.setAttribute('src', mobileImg);
      });
      document.querySelectorAll('[data-mobile-bgsrc]' as any).forEach((row) => {
        const bgCss: any = 'url(' + row.getAttribute('data-mobile-bgsrc') + ')';
        row.style.backgroundImage = bgCss;
      });
    } else {
      document.querySelectorAll('[data-pc-imgsrc]' as any).forEach((row) => {
        const pcImg: any = row.getAttribute('data-pc-imgsrc');
        row.setAttribute('src', pcImg);
      });
      document.querySelectorAll('[data-pc-bgsrc]' as any).forEach((row) => {
        const bgCss: any = 'url(' + row.getAttribute('data-pc-bgsrc') + ')';
        row.style.backgroundImage = bgCss;
      });
    }
  }

  public dDay() {
    document.querySelectorAll('[data-end-date]').forEach((row) => {
      const textDate: any = row.getAttribute('data-end-date');
      const end: any = moment(textDate).format('YYYY-MM-DD');
      const endTime = new Date(end).setHours(23, 59, 59);

      setInterval(() => {
        const distance = new Date(endTime).getTime() - new Date().getTime();
        const d = Math.floor(distance / (1000 * 60 * 60 * 24));
        const h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const s = Math.floor((distance % (1000 * 60)) / 1000);

        if (d > 0) {
          row.innerHTML = '남은시간 ' + d + '일';
        } else if (d === 0) {
            row.innerHTML = `남은시간 ${h}시 ${m}분 ${s}초`;
        } else {
          const endColor: string = 'background-color: #c4bbab;';
          row.innerHTML = '이벤트 종료';
          row.setAttribute('style', endColor);
        }
      }, 1000);
    });
  }

  public staticBack() {
    if (this.isApp) {
      if (this.version === 'android') {
        window.poingApp.close();
      } else {
        const messageToPost = { method: 'close' };
        window.webkit.messageHandlers.scriptHandler.postMessage(messageToPost);
      }
    } else {
      this.$router.go(-1);
    }
  }
  get copyContents() {
    return this.staticweb.share_info.body;
  }
  public copyNoti() {
    if (!this.isApp) {
      this.$q.notify({
        message: '정보가 클립보드에 저장되었습니다. <br>원하는 곳에 붙여넣기 하세요.',
        html: true
      });
    }
  }
  public staticShare() {
    if (this.isApp) {
      const popupTitle = '가이드 공유';
      const contentsText = this.copyContents;
      if (this.version === 'android') {
        window.poingApp.share(contentsText, popupTitle);
      } else {
        const messageToPost = { method: 'share', popupTitle, contentsText};
        window.webkit.messageHandlers.scriptHandler.postMessage(messageToPost);
      }
    } else {
      return null;
    }
  }

  public shareAction() {
    document.querySelectorAll('.share').forEach((button) => {
      const link = `${window.location.origin}${window.location.pathname}`;
      const { classList } = button;
      const { title, description, thumbnail } = this.staticweb.content.meta;

      button.addEventListener('click', (event) => {
        const category = button.getAttribute('data-category');
        const action = button.getAttribute('data-action');
        const buttontext = button.getAttribute('data-buttontext');

        if (classList.contains('facebook')) {
          FB.ui({
            method: 'feed',
            name: title,
            description,
            picture: thumbnail,
            link,
            display: 'popup'
          });
        } else if (classList.contains('twitter')) {
          window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}&text=${title}`);
        } else if (classList.contains('kakaotalk')) {
          Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
              title,
              description,
              imageUrl: thumbnail,
              link: {
                webUrl: link,
                mobileWebUrl: link
              },
              imageWidth: 476,
              imageHeight: 300
            },
            buttons: [
              {
                title: buttontext,
                link: {
                  webUrl: link,
                  mobileWebUrl: link
                }
              }
            ],
            installTalk: true
          });
        }
      })
    });
  }

  get staticweb() {
    return staticwebModule.staticwebContents;
  }
  get popupContent() {
    return staticwebModule.mainPopup;
  }
}
